// src/services/auth.service.ts
import {
    GoogleAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    UserCredential
} from "firebase/auth";
import {auth} from "@/config/firebase.config"; // Ensure you have Firebase initialized here
import router from "@/router";
import apiClient from "@/services/axios.service";
import {UserInterface} from "@/interfaces/user.interface";

export class AuthService {
    private user: UserInterface | undefined;

    constructor() {
        // Initialization logic if necessary
    }

    getUser = async (): Promise<UserInterface | undefined> => {
        try {

            if (this.user) {
                return this.user;
            }

            const response = await apiClient.get("/getUser");
            if (response.data) {
                this.user = response.data;
            }
            return response.data as UserInterface;
        } catch (error: any) {
            console.log(error);

            throw error;
        }
    };


    async login(email: string, password: string): Promise<UserCredential> {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log("Login successful:", userCredential.user);

            const currentUser = await this.getUser();

            if (currentUser?.role === "admin") {
                await router.push({
                    path: "/secure/affiliates/overview",
                });
            } else if(currentUser?.id) {
                // Routing to the affiliate dashboard for non-admin users
                await router.push({
                    path: "/secure/dashboard",
                    query: {
                        id: currentUser.id,
                    },
                });
            }

            return userCredential;  // Return the user credentials for further use if needed
        } catch (error: any) {
            console.error("Login failed:", error);

            // You can add specific error handling based on Firebase error codes
            if (error.code === "auth/wrong-password") {
                throw new Error("The password is incorrect. Please try again.");
            } else if (error.code === "auth/user-not-found") {
                throw new Error("No user found with this email address.");
            } else {
                // Handle generic errors
                throw new Error("Login failed. Please check your credentials and try again.");
            }
        }
    }

    async loginWithGoogle(): Promise<UserCredential> {
        try {
            const provider = new GoogleAuthProvider();
            const userCredential = await signInWithPopup(auth, provider);
            console.log("Google login successful:", userCredential.user);

            await router.push({name: "Dashboard"});
            return userCredential;
        } catch (error) {
            console.error("Google login failed:", error);
            throw error;  // Handle error in the UI
        }
    }

    async sendPasswordReset(email: string): Promise<void> {
        try {
            await sendPasswordResetEmail(auth, email);
            console.log("Password reset email sent successfully");
        } catch (error) {
            console.error("Error sending password reset email:", error);
            throw error;  // Handle error in the UI
        }
    }

    // Add the logout method
    async logout(): Promise<void> {
        try {
            await signOut(auth);  // Sign out from Firebase auth
            console.log("Logout successful");
            this.user = undefined;
            // Optionally, clear any user-specific state or tokens here

            await router.push({name: "Login"});  // Redirect to login page
        } catch (error) {
            console.error("Logout failed:", error);
            throw error;  // Handle error in the UI
        }
    }
}

// Usage example
const authService = new AuthService();
export default authService;
