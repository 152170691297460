import {NavigationGuardNext, RouteLocationNormalized} from 'vue-router';
import authService from "@/services/auth.service";
import {useToast} from "primevue/usetoast";

export const affiliateRoleGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const toast = useToast(); // Initialize useToast once
    try {
        const user = await authService.getUser()
        if (user && user.role !== 'admin') {
            next()
        } else {
            toast.add({
                summary: 'Permission error',
                detail: 'Invalid permission role (a2)',
                severity: "error",
                closable: true,
                life: 2500
            })
            const name = from?.name ? from.name : 'Login';
            next({name: name});
        }
    }
    catch (error) {
            toast.add({
                summary: 'Error',
                detail: 'An error occurred while checking permissions',
                severity: "error",
                closable: true,
                life: 2500
            });
        const name = from?.name ? from.name : 'Login';
        next({name: name});
        }

};

