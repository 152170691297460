import {NavigationGuardNext, RouteLocationNormalized} from 'vue-router';
import {auth} from '@/config/firebase.config'; // Firebase initialized
import {onAuthStateChanged} from 'firebase/auth';

export const authGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
            next()
        } else {
            next({name: 'Login'}); // Redirect to login if not authenticated
        }
    });
};

